

.menutoggle {
    display: block;
    position: relative;
    transition: .5s;
    width: 24px;
    height: 18px;
    z-index: 9999;
    transition: .4s;
    
    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      background: $white;
      left: 0;
      top: 0;
      height: 2px;
      width: 100%;
      border-radius: 2px;
      transform-origin: center center;
      transition: .4s;
      transform: rotate(0deg);
    }
    
    &:after {
      top: 16px;
    }
  
    &:hover {
  
      &:before {
        top: 1px;
      }
  
      &:after {
        top: 15px;
      }
  
    }
    
    div {
      border-radius: 2px;
      position: absolute;
      left: 0;
      top: 9px;
      transform: translateY(-50%);
      background: $white;
      height: 2px;
      width: 100%;
      opacity: 1;
      transition: .4s;
      transition-delay: .5s;
    }
  
    .off-canvas-content.is-open-top & {
        
      div {
        opacity: 0
      }
  
      &:before,
      &:after {
        transition-delay: .5s;
        top: 9px;
      }
  
      &:before {
        transform: rotate(-45deg);
      }
  
      &:after {
        transform: rotate(45deg);
      }
    }
  }