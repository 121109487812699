html body.woocommerce {
  // PRODUCT TEASERw

  .tease-product {
    background: white;
    text-align: center;
    z-index: 10;
    margin-bottom: 2rem;
    width: 100%;
    transition: 0.5s;
    border: none;
    border: 1px solid #ddd;

    .card {
      padding-top: 2rem;
    }

    &:hover {
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
      transform: translateY(2px);
      border: 1px solid #fff;
      cursor: pointer;
    }

    &__btn {
      position: absolute;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
      text-align: center;
      padding: 0.5rem 1rem;
      background: $brandcolor2;
      color: white;
      width: auto;
      margin: 0;
    }

    .product__content {
      .brand {
        max-width: 120px;
        max-height: 40px;
      }
    }

    .onsale {
      margin: 0 auto;
      position: absolute;
      left: 1rem;
      top: 1rem;
      right: 1rem;
      border-radius: 0px;
      background: $brandcolor6;
      color: white;
      font-size: 16px;
      display: block;
      //left: 50%;
      //transform: translateX(-50%);
      min-height: 0;
      line-height: 1;
      padding: 0.7rem 1rem;
      z-index: 100;
    }

    &__figure {
      position: relative;
      width: 100%;
    }

    .card-section:last-child {
      padding-top: 0;
    }

    .product-title {
      @include fluid-type(14px, 16px);
      color: $brandcolor3;
    }

    .product__actions {
      position: absolute;
      bottom: 0;
      left: -0.5rem;
      right: -0.5rem;
      z-index: -1;
      height: 48px;
      height: 0;
      overflow: hidden;
      transition: 0.2s;

      .cell {
        position: relative;
        top: 0px;
        transition: 0.4s;

        &:nth-child(1) {
          transition-delay: 0.2s;
          top: -48px;
        }
        &:nth-child(2) {
          transition-delay: 0.4s;
          top: -48px;
        }
        &:nth-child(3) {
          transition-delay: 0.8s;
          top: -48px;
        }
      }
    }

    &:hover {
      .product__actions {
        bottom: -64px;
        height: 48px;

        .cell {
          top: 0;
        }
      }
    }
  }

  // TABS PRODUCTS

  .woocommerce-tabs {
    margin: 0 auto;
    @extend .grid-container;
  }

  .woocommerce-tabs {
    background: #edeff0;
    padding: 4rem;

    //tabs
    .wc-tabs {
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;

      li {
        padding: 0 2rem;
        position: relative;

        &.active {
          a:after {
            right: 0;
          }
        }

        a {
          display: block;
          font-weight: 600;
          font-size: 15px;
          text-transform: uppercase;
          @extend .mark-ltr;
          font-weight: $header-font-family;
        }
      }
    }

    .woocommerce-Tabs-panel {
      max-width: 800px;
      margin: 0 auto;
    }
  }

  // WOOCOMMERCE FILTERS SIDEBAR

  .woocommerce-widget-layered-nav,
  .widget_price_filter,
  .widget_rating_filter {
    font-family: $header-font-family;
    @include fluid-type(18px, 20px);
    border-top: 4px solid #f1f2f3;
    padding-top: 0.5rem;
    margin-bottom: 2rem;

    .price_slider_wrapper {
      padding: 1rem 0;

      .price_slider_amount {
        font-family: $body-font-family;
        font-size: 15px;
      }
    }

    .woocommerce-widget-layered-nav-list {
      margin: 0;
    }

    .woocommerce-widget-layered-nav-list__item {
      display: flex;
      font-family: $body-font-family;
      font-size: 15px;

      .count {
        padding: 0 0.5rem;
      }
    }

    .wc-layered-nav-term {
    }
  }

  .woocommerce-result-count {
    margin: 0;
  }

  .woocommerce-ordering {
    margin: 0;

    select {
      margin: 0;
    }
  }

  .ordering-bar {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  .widget_price_filter .ui-slider .ui-slider-range {
    background: $brandcolor3;
  }

  .widget_price_filter .ui-slider .ui-slider-handle {
    background: $brandcolor6;
  }

  .widget_price_filter .price_slider_wrapper .ui-widget-content {
    background: lightgrey;
  }

  // MARKETING QUOTE & ONSALE BADGE
  .marketing-quote,
  .onsale {
    background: $brandcolor6;
    color: #fff;
    padding: 0.5rem 2rem;
    font-weight: 600;
    position: absolute;
    left: -1rem;
    top: 1rem;
    z-index: 10;
    border-radius: 0;
    display: block;
    font-size: 15px;
    line-height: 15px;
    min-height: 0;
  }

  // DISPLAY PRICE TEASER
  // <span class="price"><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">€</span>999.95</span></span>

  .tease-product {
    display: flex;

    .price {
      @include fluid-type(13px, 14px);

      del {
      }
      ins {
        @include fluid-type(15px, 17px);
      }

      .woocommerce-Price-amount {
        font-weight: 600;

        .woocommerce-Price-currencySymbol {
          display: none;
        }
      }
    }

    a {
      display: flex;
    }

    .card {
      .card-section {
        &:first-child {
          align-items: center;
          display: flex;
        }

        &:last-child {
          align-items: flex-end;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

//  LIJST PRODUCTGROEPEN

.productgroups .woocommerce {
  .product-category {
    //padding: 1rem !important;

    a {
      display: block;
      transition: 0.5s;
      background: white;
      position: relative;

      &:hover {
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.3);
        transform: translateY(-2px);
      }
    }

    img {
      border: 16px solid white;
      margin-bottom: 0 !important;
    }

    .woocommerce-loop-category__title {
      background: $brandcolor3;
      color: white;
      padding: 1rem 1rem !important;
      text-align: center;

      .count {
        display: none;
      }
    }
  }
}

// SINGLE PRODUCT

.product {
  position: relative;

  .onsale {
    display: block;
  }

  &__slider {
    img {
      width: auto !important;
    }
  }

  &__summary {
  }
}

.woocommerce-product-details__short-description {
  margin-bottom: 2rem;
}

// META DATA
.product_meta {
  margin-bottom: 2rem;
  font-size: 90%;

  .posted_in,
  .sku_wrapper {
    font-weight: 600;
    display: block;

    a,
    .sku {
      font-weight: 300;
    }
  }
}

.productinfo-show-discounts {
  margin-bottom: 2rem !important;
  color: $brandcolor4;
  font-weight: 600;
}

.slick-slider {
  //border: 1px solid $brandcolor2;

  .product__slider & {
  }
}

.woocommerce-product-gallery__image {
  img {
    //width: 100% !important;
  }

  .slick-slide {
    position: relative;
    margin-bottom: 1rem;

    &:after {
      content: "";
      background: rgba(0, 0, 0, 0.05);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.wpis-slider-nav {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  li:focus {
    outline-color: transparent;
    outline-style: none;
  }

  img {
    width: 100% !important;
    &:focus {
      outline-color: transparent;
      outline-style: none;
    }
  }

  .slick-slide {
    padding: 0 0.5rem;
    position: relative;
    margin-bottom: 1rem;

    &:after {
      content: "";
      background: rgba(0, 0, 0, 0.05);
      position: absolute;
      left: 0.5rem;
      right: 0.5rem;
      top: 0;
      bottom: 0;
    }
  }
}

.brand {
  max-height: 80px;
  max-width: 80px;
}

.single_variation_wrap {
  // force to set add-to-cart button on own row
  width: 100%;
}

html body.woocommerce-cart {
  .page-title {
    @include xy-grid-container;
    color: $brandcolor2;
  }

  .section--main > .woocommerce {
    @include xy-grid-container;
    @include xy-grid;

    .woocommerce-cart-form {
      @include xy-cell(8);
    }

    .cart-collaterals {
      @include xy-cell(4);
    }
  }

  .woocommerce .cart-collaterals .cart_totals,
  .woocommerce-page .cart-collaterals .cart_totals {
    width: 100%;
    float: none;
  }
}

#yith-wcwl-form {
  max-width: $global-width;
  margin: 0 auto;
}

// WISHLIST
.woocommerce-wishlist {
  .woocommerce table.shop_table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    font-size: 100%;

    .dateadded {
      margin-bottom: 1rem;
    }

    thead {
      display: none;
    }
  }

  .woocommerce #respond input#submit.alt,
  .woocommerce a.button.alt,
  .woocommerce button.button.alt,
  .woocommerce input.button.alt {
    background-color: $brandcolor2;
    color: #fff;
    -webkit-font-smoothing: antialiased;
  }
}

.grid-sidebar {
  .woocommerce {
    @include xy-grid-container;
    @include xy-grid;

    .woocommerce-cart-form {
      @include xy-cell(8);
      padding-right: 24px;
    }

    .cart-collaterals {
      @include xy-cell(4);
      padding-left: 24px;
    }
  }
}

html {
  .woocommerce table.shop_table {
    border: 0;
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 0;

    td {
      padding: 24px 12px;
    }

    thead {
      background: #fff;
    }

    tbody tr:nth-child(even) {
      border-bottom: 0;
      background-color: #fff;
    }
  }
}

html .woocommerce {
  .cart_totals {
  
    h2 {
      @include fluid-type(16px, 20px);
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
  
    table {
      th {
        padding: 1rem 0;
      }
    }
  }
}
