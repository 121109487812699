/* vaste verhoudingen */

/* Een div moet gewrapped worden met een div.ratio. optioneel met een additionele
class voor andere verhoudingen */

.ratio {
    position: relative;
    margin-bottom: 12px;
  
    &:before {
      content: "";
      display: block;
      padding-top: 100%; 	/* initial ratio of 1:1*/
    }
  
    .box {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-bottom: 0;
    }
  
  }
  
  /* vaste verhoudingen */
  .ratio--portrait:before  { padding-top: 156.779661016949%; }
  .ratio--landscape:before { padding-top: 76.271186440678%;  }