// idTag
.idTag {
    span {
      display: none;
    }
}

  // section
.section {
  padding: 1rem;

  &--flush {
    padding: 0;
  }
}




// wpcf7-form

.wpcf7-form {
  br {
    display: none;
  }
}