.topbar {
    background: black;
  
    &__logo,
    &__nav {
      align-items: center;
      display: flex;
    }
  
    &__logo {
      
    }
  
    &__nav {
    }
  
  
  }


  // <header class="section  topbar  hide-on-small">

  //       <a href="mailto:info@roskamdaktechniek.nl"  class="icon  icon--mail  topbar__email">info@roskamdaktechniek.nl</a>
    
  //       <nav class="menu  show-for-medium  topbar__nav" role="navigation">
  //           {% include "menu.twig" with {'menu': menu.get_items} %}
  //       </nav>

  //       <a href="tel:0625000041"  class="icon  icon--phone topbar__phone">+31 (0)6 25 00 00 41</a>

  //       <a href="#" class="menutoggle  show-for-small-only  topbar__menutoggle" data-close  aria-label="Close menu" data-toggle="offCanvasID">
  //           <div></div>
  //       </a>
    
  //   </header>