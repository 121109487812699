.footer-container {
  margin: 0 auto;
  border-top: 1px solid $medium-gray;
  margin-top: rem-calc(60);
}

.footer {
  @include xy-grid-container;
  @include xy-grid;
  padding: rem-calc(30) 0;

  article {
    @include xy-cell(auto);
    margin: 0;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
