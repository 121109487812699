@charset 'utf-8';

@import "settings";
@import "foundation";
@import "motion-ui";

// webchimp eigen components vanuit webchimp-base
@import "../../../../webchimp-base/src/assets/scss/webchimp/menutoggle";
@import "../../../../webchimp-base/src/assets/scss/webchimp/topbar";
@import "../../../../webchimp-base/src/assets/scss/webchimp/webchimp";
@import "../../../../webchimp-base/src/assets/scss/global/ratio";

// @import '../../../../webchimp-base/src/assets/scss/elementor/elementor-column-fix';

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-flex-grid;

@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
//@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
//@include foundation-slider;
@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/*
  My custom styles:
  Add your scss files below to structure your project styles
*/

// Global
@import "global/_accessibility";
@import "global/colors";
@import "global/wp-admin";
@import "global/wp-overrides";

// Modules
@import "modules/navigation";
@import "modules/content";
@import "modules/footer";
@import "modules/editor-style";

// Components
@import "components/buttons";
@import "components/cards";
@import "components/featured-image";
// @import "components/flexslider";
@import "components/dividers";
@import "components/links";

//@import "woocommerce/flatsome-shop";

// Templates
// @import "templates/front";
// @import "templates/kitchen-sink";

// FONTS

@font-face {
  font-family: "montserrat";
  src: url("../fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-bold-webfont.woff2") format("woff2"),
    url("../fonts/opensans-bold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-bolditalic-webfont.woff2") format("woff2"),
    url("../fonts/opensans-bolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-italic-webfont.woff2") format("woff2"),
    url("../fonts/opensans-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "opensans";
  src: url("../fonts/opensans-regular-webfont.woff2") format("woff2"),
    url("../fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// COLORS

$brandcolor1: #000000;
$brandcolor2: #192e3b;
$brandcolor3: #00437b;
$brandcolor4: #36a9e1;
$brandcolor5: #cbeaf7;
$brandcolor6: #96bd42;

// pencils
.brandcolor1 {
  color: $brandcolor1;
}
.brandcolor2 {
  color: $brandcolor2;
}
.brandcolor3 {
  color: $brandcolor3;
}
.brandcolor4 {
  color: $brandcolor4;
}
.brandcolor5 {
  color: $brandcolor5;
}
.brandcolor6 {
  color: $brandcolor6;
}
.white {
  color: #fff;
}

// RESPONSIVE TYPOGRAPHY MIXIN

@mixin fluid-type(
  $min-font-size: 12px,
  $max-font-size: 21px,
  $lower-range: 420px,
  $upper-range: 1200px
) {
  font-size: calc(
    #{$min-font-size} + #{(
        ($max-font-size / ($max-font-size * 0 + 1)) -
          ($min-font-size / ($min-font-size * 0 + 1))
      )} * ((100vw - #{$lower-range}) / #{(
            ($upper-range / ($upper-range * 0 + 1)) -
              ($lower-range / ($lower-range * 0 + 1))
          )})
  );
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range) {
    font-size: $max-font-size;
  }
}

@import "woocommerce/woocommerce";

// CUSTOM WIDTHS

.small {
  max-width: 600px;
  margin: 0 auto;
}

body {
  font-size: 15px;
  color: $brandcolor2;
  background: white;

  &.home {
    background: #eff0f1;
  }
}

// EQUAL HEIGHT FOUNDATION GRID

.equal-height-cards {
  .cell {
    display: flex;
    align-items: stretch;
  }
}

// CUSTOM BULLETS ========================

/*ul*/
.vink {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0 0 0 20px;
    background: white
      url("data:image/svg+xml,%0A%3Csvg width='14px' height='13px' viewBox='0 0 14 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 49 %2851002%29 - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='homepage' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Large-Device-Desktops' transform='translate%28-208.000000, -728.000000%29' fill='%231BAF39'%3E%3Cpath d='M214.591826,741.051758 L221.75115,728.875488 L220.088222,728 L214.199353,737.708435 L209.373475,732.211682 L208,733.651817 L214.591826,741.051758 Z' id='Rectangle-46'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      no-repeat left top 7px;
    margin-bottom: 15px;
  }
}

//  CUSTOM BULLETS ELEMENTOR IMPLEMENTATION ==============================

.uspbox .elementor-widget-container ul {
  @extend .vink;
}

// TAGS ==========================

.pills {
  text-align: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 80%;
    max-width: 680px;
    margin: 0 auto;

    li {
      display: inline-block;
      padding: 0.5rem 1rem 0.7rem 1rem;
      background: $brandcolor4;
      color: white;
      border-radius: 6px;
      margin: 0 0.5rem 1rem 0.5rem;
      line-height: 20px;
    }
  }
}

// FLOATING HEADER ==========================

.floating-header {
  .elementor-widget-container {
    text-align: center;

    .elementor-heading-title {
      display: inline-block;
      margin: 0 auto;
      padding: 0.5rem 1rem 0.7rem 1rem;
      background: $brandcolor2;
      border-radius: 6px;
      color: white;
    }
  }
}

// TAGS ==========================

.idTag {
  z-index: 100;
  margin: 0 auto 0 auto;
  position: relative;
  width: auto;
  height: 100px;

  @include breakpoint(small only) {
    position: absolute;
    left: 0;
    right: 0;
    height: 70px;
    top: 0;
  }

  body.home & {
    height: 100px;
    margin-top: -30px;

    @include breakpoint(small only) {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      position: absolute;
    }
  }
}


.dropdown.menu > li > a {
  background: transparent;
}
// SECTIONS ==========================

.section {
  padding: 1rem;

  @include breakpoint(medium) {
    padding: 2rem 2rem;
  }

  @include breakpoint(large) {
    padding: 4rem 2rem;
  }

  &--widgets {
    padding: 2rem 1rem;

    @include breakpoint(medium) {
      padding: 3rem 1rem;
    }

    @include breakpoint(large) {
      padding: 4rem 1rem;
    }
  }


  &--mobiletopbar {
    @include breakpoint(small only) {
      padding: 0;
      height: 48px;
    }

    .home & {
      height: 0;
      padding: 0;
    }
  }


  &--shade {
    background: #edeff0;

    .tease-product {
      border: none !important;
    }
  }

  &--large {
    padding: 2rem 1rem;

    @include breakpoint(medium) {
      padding: 4rem 2rem;
    }

    @include breakpoint(large) {
      padding: 8rem 2rem;
    }
  }

  &--topbar {
    padding: 5px 20px;
    width: 100%;
    z-index: 100;
    top: 0;
    transition: 0.4s;
    text-align: right;
    position: relative;
    background: $brandcolor2;
    color: white;
    height: 40px;

    .off-canvas-content.is-open-top & {
      background: none;
      .topbar__phone {
        opacity: 0;
      }
    }
  }

  &--header {
    background: white;
    color: $brandcolor2;
    padding: 0;
  }

  &--merken {
    text-align: center;
    background: #fff;

    img {
      height: auto;
      max-height: 120px;
    }
  }

  &--merken-white {
    text-align: center;
    background: #fff;

    img {
      height: auto;
      max-height: 120px;
    }
  }

  &--header-vervolg {
    text-align: center;
    padding: 12rem 0 0 0;
  }

  &--mainnav {
    padding: 0;
    position: relative;
    z-index: 100;

    @include breakpoint(small only) {
      display: none;
    }
  }

  &--hero {
    padding: 0;
  }

  &--credits {
    padding: 1rem 1rem 0.8rem 1rem;
    text-align: center;
  }

  &--quote {
    background: url(../images/quote.jpg) no-repeat center center;
  }

  &--main {
    padding: 1rem 0.5rem 1rem 0.5rem;

    @include breakpoint(medium) {
      padding: 2rem 1rem 2rem 1rem;
    }

    @include breakpoint(large) {
      padding: 2rem 1rem 2rem 1rem;
    }
  }

  &--small {
    padding: 1rem;

    @include breakpoint(large) {
      padding: 2rem 1rem;
    }
  }

  &--wide {
    padding: 1rem 2rem;

    @include breakpoint(large) {
      padding: 2rem 2rem;
    }
  }

  &--flush {
    padding: 0;
  }

  &--review {
    background: $brandcolor3;

    @include breakpoint(medium) {
      padding: 2rem;
    }

    @include breakpoint(large) {
      padding: 3rem;
    }
  }

  &--intro {
    text-align: center;

    .elementor-column-wrap {
      margin: 0 auto;
      max-width: 680px;
    }
  }

  &--pagination {
    background: $brandcolor1;
    background-size: cover;
    color: white;
    padding: 2rem;

    .grid-container {
      margin-bottom: 2rem;
    }

    a {
      color: white;
    }

    img {
      border: 6px solid white;
    }

    .media-object-section:first-child {
      padding-right: 0;
      margin-right: 1rem;
    }
  }
}

.off-canvas-wrapper {
  position: relative;
  z-index: 10;
}

.topbar-cta {
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding-left: 160px;

  a {
    color: white;
    text-decoration: underline;
  }
}

.navtopbar {
  color: white;
  font-size: 14px;
  background: transparent;

  a {
    color: white;
    opacity: 0.6;
    background: transparent!important;

    &:hover {
      opacity: 1;
    }
  }
}

// geen idee of dit goed werkt

p:last-child {
  margin: 0;
}

a {
  transition: 0.5s;
}

// MENU TOGGLE ============================================================

.menutoggle {
  position: absolute;
  left: 1rem;
  top: 1rem;

  &:before,
  &:after {
    background: $brandcolor1;
  }

  div {
    background: $brandcolor1;
  }

  .is-open-top & {
    &:before,
    &:after {
      background: white;
    }

    div {
      background: white;
    }
  }

  .home & {
    div,
    &:before,
    &:after {
      background: white;
    }
  }
}

.small-gutter {
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @include xy-grid-frame("false", 0);
}

// TYPOGRAPHY =========================================================

// HEADER
.page-title {
  font-weight: 500;
  font-size: 26px;

  @include breakpoint(medium) {
    font-size: 32px;
  }

  @include breakpoint(large) {
    font-size: 36px;
  }
}

.panel {
  
  &--blue {
    background: #00437B;
    color: white;
  }

  &--green {
    background: #96BD42;
    color: white;
  }
}

.section-title {
  font-family: $header-font-family;
  text-transform: uppercase;
  @include fluid-type(20px, 24px);
  color: $brandcolor3;
  margin-bottom: 4rem;
  text-align: center;
}

.panelhead {
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;

  &--white {
    color: white;
  }
}

// MAINNAV ===========================================================

#mainNav {
  // LEVEL 1
  .menu {

    > li {
      transition: 0.4s;

      > a {
        padding: 0.5rem 1rem;
        text-transform: uppercase;
      }

      &.current-menu-parent,
      &.current-menu-item {
        border: 1px solid white;
        background: white;
        color: $brandcolor2;

        a {
          color: $brandcolor2;
        }
      }
    }
  }

  // LEVEL 2
  > .menu .menu {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);

    > li {
      border-bottom: 1px solid #ccc;
      font-size: 85%;

      &:last-child {
        border: 0;
      }

      a {
        color: $brandcolor1;
        padding: .8rem 1rem;
        display: block;
      }

      &:hover {
        background: $brandcolor4;
        color: white;
      }
    }
  }
}

// prevent display: none;

.is-dropdown-submenu {
  overflow: hidden;
  display: block !important;
  transform: translateY(2rem);
  opacity: 0;
  transition: transform 0.5s;
  max-height: 0;
  background: white;
  box-shadow: 0 0 24px 0 rgba(0,0,0,.2);

  &.js-dropdown-active {
    overflow: visible;
    max-height: 1000px;
    transform: translateY(0.7rem);
    opacity: 1;
  }
}

.sidebar {
  margin-bottom: 30px;

  &--green {
    padding: 30px;
    background: $brandcolor2;

    .elementor-text-editor {
      color: white;
    }
  }

  &--grey {
    padding: 30px;
    color: white;
    background: $brandcolor1;

    .elementor-text-editor {
      color: white;
    }
  }

  h2,
  h3 {
    font-size: 24px;
    color: #fff;
  }
}

//@import '../../../../webchimp-base/src/assets/scss/webchimp/hero';

.hero {
  .elementor-widget-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 64px);
    text-align: center;
  }
}

.topmenu {
  @include breakpoint(small only) {
    display: none;
  }

  .menu {
    justify-content: flex-end;
  }

  a {
    color: $brandcolor1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
  }
}

.footermaps {
  width: 100%;
  height: 300px;

  @include breakpoint(medium) {
    height: 400px;
  }

  @include breakpoint(large) {
    height: 500px;
  }

  @include breakpoint(xlarge) {
    height: 600px;
  }

  @include breakpoint(xxlarge) {
    height: 800px;
  }
}

.topbar {
  background: white;
  color: $brandcolor1;
  text-align: center;
  line-height: 24px;
  height: 54px;

  .logged-in & {
    top: 32px;
  }

  &__nav {
    display: none;
    @include breakpoint(large) {
      display: block;
    }
  }

  &__email {
    font-weight: bold;
    display: none;
    color: $brandcolor2;

    @include breakpoint(large) {
      display: block;
      float: left;
    }
  }

  &__phone {
    font-weight: bold;
    float: left;
    display: block;
    color: $brandcolor2;

    @include breakpoint(large) {
      float: right;
    }
  }

  &__toggle {
    float: right;
    @include breakpoint(large) {
      display: none;
    }
  }
}

.callout {
  padding: 0;
  border: 0;

  figcaption {
    padding: 1rem;
  }

  &--form {
    padding: 3rem;
  }

  &--intro {
    background: $brandcolor2;
  }

  &--opening {
    border: 1px solid white;
  }
}

.call-to-action {
  background: $brandcolor2;
  padding: 2rem;
  color: white;

  .elementor-image-box-title {
    font-size: 24px;
    padding-left: 1rem;
    margin: 0;
    color: #fff;
  }

  .elementor-image-box-content {
    font-size: 1rem;

    h3 {
      color: #fff;
    }

    p {
      margin-bottom: 0;
    }
  }

  .elementor-image-box-img img {
    border-radius: 50%;
    width: 140px;
    height: auto;
  }
}

.uspbox {
  padding: 40px;
  margin-top: -8rem;
  background: white;
  color: $brandcolor1;

  &__logo {
    margin-bottom: 1rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.overview {
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 14px;
    //text-transform: uppercase;
    font-weight: bold;
  }

  a {
    color: white;
    transition: 0.4s;

    &:hover {
      text-decoration: underline;
    }
  }
}

.photoframe {
  .elementor-image img {
    border: 12px solid #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
  }
}

.intro {
  font-size: 130%;
  font-weight: 600;
  text-align: center;
}

// CALL TO ACTION (elementor)

.calltoaction {
  background: lightgray;
  max-width: 880px;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  padding: 1rem;

  @include breakpoint(large) {
    padding: 2rem;
  }
}

// ELEMENTOR BUTTON CLASSES ==========================================================

.btn {

  .elementor-button.elementor-button-link {
    background: grey;
    color: #fff;
    border-radius: 3px;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 18px;
  }

  &--inline {
    display: inline-block;
    margin: 0 0.5rem;
  }

  &--block {
    display: block;

    .elementor-button-link {
      display: block;
    }
  }

  &--ghost {
    .elementor-button.elementor-button-link {
      background: transparent;
      border: 1px solid white;
      color: white;

      &:hover {
        &:after {
          left: 6px;
        }
      }
    }

    + btn {
      margin-left: 1rem;
    }
  }

  &--diap {
    .elementor-button.elementor-button-link {
      background: #fff;
      border: none;
      color: $brandcolor1;
      box-shadow: 0 0 24px 0 lighten($brandcolor1, 60%);

      &:after {
        content: "\00a0 »";
      }

      &:hover {
        box-shadow: 0 0 6px 0 lighten($brandcolor1, 60%);
      }
    }
  }

  &--blue {
    .elementor-button.elementor-button-link {
      background-color: $brandcolor5;
      min-width: 320px;
      max-width: 320px;
    }
  }

  &--yellow {
    .elementor-button.elementor-button-link {
      background-color: $brandcolor3;
      min-width: 320px;
      max-width: 320px;
    }
  }

  &--orange {
    .elementor-button.elementor-button-link {
      background-color: $brandcolor4;
      min-width: 320px;
      max-width: 320px;
    }
  }
}

// FOUNDATION BUTTON CLASSES ===========================================

.button {
  font-weight: bold;

  &--gold {
    color: white;
    background: $brandcolor2;

    &:hover {
      background: darken($brandcolor2, 5%);
    }
  }

  &--dark {
    color: white;
    background: $brandcolor2;

    &:hover {
      opacity: 0.8;
    }
  }

  &--ghost {
      border: 1px solid white;
      border-radius: 3px;
      color: white;
      background: transparent;
  
      &:hover {
        background: rgba(254,254,254,.2);
      }
  }

  &--diap {
    border: 1px solid white;
    border-radius: 3px;
    background: #fff;
    color: $brandcolor2;
    margin: 0;

    &:hover {
      border: 1px solid white;
      color: white;
      background: transparent;
    }
  }
}

// ICON CLASSES ===========================

.icon {
  padding-left: 36px;

  &--mail {
    background: transparent
      url(/wp-content/uploads/sites/5/2018/01/icon-mail.png) no-repeat left
      center;
    background-size: contain;
  }

  &--phone {
    background: transparent
      url(/wp-content/uploads/sites/5/2018/01/icon-phone.png) no-repeat left
      center;
    background-size: contain;
  }
}

// BREADCRUMBS ======================

.wc-breadcrumb {
  background: $brandcolor2 url(../images/breadcrumb-background.jpg) no-repeat
    center center;
  background-size: cover;
  color: white;
  text-transform: lowercase;
  padding: 1rem;
  font-size: 15px;
  align-items: center;
  text-transform: uppercase;
  font-family: $header-font-family;
  letter-spacing: 0.39px;

  @include breakpoint(medium) {
    padding: 3rem 2rem;
  }

  @include breakpoint(large) {
    padding: 4rem 2rem;
  }

  .home {
    background: url(../images/icons/icon-home.svg) no-repeat center center;
    background-size: contain;
    width: 16px;
    height: 16px;
    display: block;

    span {
      display: none;
    }
  }

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb_last {
    opacity: 0.6;
  }
}

.image-separator {
  background: $brandcolor2 url(../images/breadcrumb-background.jpg) no-repeat center center;
  background-size: cover;
  height: 60px;
  max-width: none;
  margin: 0;
}

html .boxzilla {
  width: 80vw;
  max-width: 680px;
  padding: 0;

  input {
    margin: 0;
  }
}

// ELEMENTOR MEDIA-BOX STYLING

.elementor-container {
  .mediabox {
    color: $brandcolor4;

    figure {
      text-align: center;
      margin-bottom: 0;

      img {
        max-width: 58px;
        margin-bottom: 0;
      }
    }

    .elementor-image-box-title {
      color: $brandcolor4;
      font-size: 20px;
      margin: 0;
    }

    &--cta {
      .elementor-image-box-title {
        color: white;
      }

      figure {
        img {
          max-width: 90px;
        }
      }
    }
  }
}

// ELEMENTOR IMAGES =========================

.elementor-image {
  figure {
    img {
      width: 100%;
      height: auto;
    }
    figcaption {
      text-align: left;
      background: #eee;
      padding: 0.5rem 1rem;
    }
  }
}

// HERO STYLES ==============================

// .hero {
//   position: relative;
//   overflow: hidden;
//   background: $brandcolor1;
//   text-align: center;
//   height: 200px;
//   width: auto;

//   @include breakpoint(medium)  { height: 300px; }
//   @include breakpoint(large)   { height: 400px; }
//   @include breakpoint(xlarge)  { height: 500px; }
//   @include breakpoint(xxlarge) { height: 600px; }

//   img {
//     position: relative;
//     height: 100%;
//     width: auto;
//     max-width: none;
//     top: 50%;
//     transform:translateY(-50%);
//   }

//   div {
//     position: absolute;
//     left: -10px;
//     right: -10px;
//     top: -10px;
//     bottom: -10px;
//     filter: blur(120px);
//     background-size: cover;
//   }

// }

// SITE FOOTER =========================

.site-footer {
  background: white;
  color: $brandcolor2;

  @include breakpoint(medium) {
    background-position: center top 3rem;
    background-size: cover;
  }

  @include breakpoint(large) {
    background-position: center top 1rem;
    background-size: cover;
  }

  .idTag {
    position: relative;
    width: 138px;
    height: 138px;
    left: -14px;
    max-height: none !important;
  }

  .footer-widgets {
    font-size: 15px;

    @include breakpoint(small only) {
      h4 {
        margin-top: 2rem;
      }
    }

    @include breakpoint(large) {
      text-align: left;
    }

    h4 {
      font-size: 16px;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    a {
      //color: $brandcolor2;
      text-decoration: none;
      //padding: 0;
      display: block;
      line-height: 24px;
      transition: 0.4s;

      &:hover {
        color: $brandcolor2;
      }
    }

    ul.menu {
      flex-direction: column;

      a {
        padding: 0;
      }

      a:before {
        content: "» ";
      }
    }
  }

  .footer-credits {
    font-size: 14px;
    color: $brandcolor2;
    border-top: 4px solid #edeff0;

    a {
      color: $brandcolor2;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// OFFCANVAS STYLES ===============================

.off-canvas {
  color: white;
  min-height: 100vh;
  background: $brandcolor1;
  background-size: 122px auto;
  display: flex;
  justify-content: center;
  text-align: center;

  .menu.vertical {
    margin-bottom: 48px;
  }

  a {
    color: white;
    font-size: 18px;
    line-height: 24px;
  }

  .dropdown a {
    opacity: .6;
    font-size: 85%;
  }

  &.position-left {
    flex-direction: column;
  }
}

.scrollbox {
  overflow: auto;
}

.close-button {
  color: white;
  font-size: 36px;
}

.vertical.menu.accordion-menu {
  width: 100%;

  nav {
    display: block;
    flex: 0;
  }

  .has-submenu-toggle > a {
    margin-right: 0;
  }

  button {
    top: 4px;
    z-index: 100;
  }
}

// GRID OBJECT FIT  ===============================

.grid-object-fit {
  .cell {
    padding-bottom: 2rem;

    .cell:nth-child(2) {
      padding-bottom: 0;
    }

    .project {
      height: 100%;
    }

    .project__image {
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.center-div {
  @extend .grid-container;
}

.webchimp-form {
  input,
  textarea {
    border: 1px solid rgba(254, 254, 254, 0.2);
    background: transparent;
    border-radius: 4px;
    color: #fff;
    resize: none;
    margin: 0 0 1rem 0;

    &:focus {
      background: transparent;
      border: 1px solid rgba(254, 254, 254, 0.6);
      box-shadow: none;
    }
  }

  &--panel {
    input,
    textarea {
      border: 0;
      background: lighten($brandcolor2, 50%);
      color: $brandcolor2;
      margin-bottom: 1rem;

      &:focus {
        background: lighten($brandcolor2, 40%);
        box-shadow: none;
      }
    }
  }
}

// CUSTOM ICONS ====================================================

.icon-container {
  display: flex;
  margin-bottom: 1rem;
}

.icon {
  height: 1em;
  width: 1em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 8px;
  position: relative;
  bottom: -0.125em;

  &--location {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='19px' height='28px' viewBox='0 0 19 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 49 %2851002%29 - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='footer' transform='translate%28-286.000000, -154.000000%29' fill='%23FFFFFF'%3E%3Cpath d='M295.948419,154.483889 C291.002702,154.483889 286.979062,158.490297 286.979062,163.414775 C286.979062,165.398189 288.251059,168.631833 290.867523,173.300536 C292.718302,176.602637 294.538629,179.35598 294.61511,179.471541 L295.948419,181.483889 L297.281671,179.471599 C297.35821,179.35598 299.178653,176.602695 301.029199,173.300594 C303.64578,168.631833 304.91766,165.398247 304.91766,163.414833 C304.917719,158.490297 300.894078,154.483889 295.948419,154.483889 L295.948419,154.483889 Z M295.948419,167.983889 C293.380715,167.983889 291.299097,165.911243 291.299097,163.354477 C291.299097,160.797711 293.380715,158.725066 295.948419,158.725066 C298.516124,158.725066 300.597742,160.797711 300.597742,163.354477 C300.597742,165.911243 298.516066,167.983889 295.948419,167.983889 L295.948419,167.983889 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &--mobile {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='24px' viewBox='0 0 16 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 49 %2851002%29 - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='footer' transform='translate%28-287.000000, -278.000000%29' fill='%23FFFFFF'%3E%3Cpath d='M300.714286,278 L289.285714,278 C288.028571,278 287,278.981818 287,280.181818 L287,299.818182 C287,301.018182 288.028571,302 289.285714,302 L300.714286,302 C301.971429,302 303,301.018182 303,299.818182 L303,280.181818 C303,278.981818 301.971429,278 300.714286,278 L300.714286,278 Z M300.714286,297.636364 L289.285714,297.636364 L289.285714,282.363636 L300.714286,282.363636 L300.714286,297.636364 L300.714286,297.636364 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &--mail {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='21px' height='17px' viewBox='0 0 21 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 49 %2851002%29 - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='footer' transform='translate%28-285.000000, -351.000000%29' fill='%23FFFFFF'%3E%3Cpath d='M303.507536,351 L287.492519,351 C286.118155,351 285,352.090412 285,353.430672 L285,364.766016 C285,366.106276 286.118155,367.196688 287.492519,367.196688 L303.507536,367.196688 C304.8819,367.196688 306,366.106276 306,364.766016 L306,353.430672 C306,352.090412 304.8819,351 303.507536,351 L303.507536,351 Z M303.507536,365.050799 L287.492519,365.050799 C287.290497,365.050799 287.113316,364.917755 287.113316,364.766016 L287.113316,354.929592 L294.356482,361.139233 C294.504976,361.266584 294.693808,361.336678 294.888246,361.336678 L296.111534,361.336734 C296.305862,361.336734 296.494804,361.266584 296.643353,361.139233 L303.886849,354.929368 L303.886849,364.766127 C303.886739,364.917755 303.709503,365.050799 303.507536,365.050799 L303.507536,365.050799 Z M295.500027,359.205968 L288.455146,353.145889 L302.544909,353.145889 L295.500027,359.205968 L295.500027,359.205968 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}

// EQUAL HEIGHT ELEMENTOR COLUMNS

.elementor {
  .equalheight {
    .elementor-column-wrap {
      display: flex;

      .elementor-widget-wrap {
        display: flex;
      }
    }
  }
}

// CARD (elementor Afbeeldingen box)

.card {
  background: transparent;

  > div {
    margin-bottom: 0;
    padding: 1rem;

    @include breakpoint(large) {
      padding: 2rem;
    }
  }

  // header
  .elementor-image-box-title {
    //padding: 1rem 1rem 0 1rem;

    a {
    }
  }

  // body
  .elementor-image-box-description {
    //padding: 0 1rem 1rem 1rem;

    a {
    }
  }

  // image box
  .elementor-image-box-img {
  }

  &--white {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);

    .elementor-image-box-description,
    .elementor-image-box-title {
      color: white;
      color: $brandcolor1;
    }
  }

  &--purple {
    background: $brandcolor1;
    color: white;

    .elementor-image-box-description,
    .elementor-image-box-title {
      color: white;
    }
  }
}

/* WEBCHIMP ELEMENTOR STYLES */

.elementor-element {
  // ACCORDION ( elementor vertical accordion )

  .elementor-accordion {
    color: $brandcolor1;

    .elementor-accordion-item {
      margin-bottom: 1rem;
      background: rgba(254, 254, 254, 0.4);
      border: none;

      .elementor-tab-title {
        &.elementor-active {
          background: white;
        }
      }

      .elementor-tab-content {
        background: white;

        .elementor-active {
          background: white;
        }
      }
    }
  }
}

// STYLING BUSINESS HOURS WIDGETS

.mb-bhi-display {
  border: 1px solid white;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  display: inline-block;

  &.mb-bhi-open {
  }
}

// PHOTOFRAMES =========================================

.frame {
  &--1 {
    border: 6px solid #ffffff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.19);
  }

  &--xxx {
  }
}

// SPLITBOX ============================================

.splitbox {
  .elementor-row {
    .splitbox__column:nth-child(2) .elementor-column-wrap {
      padding: 2rem;

      .elementor-widget {
        @include breakpoint(large) {
          max-width: $global-width/2 - 2rem;
          margin-right: auto;
        }
      }
    }
    .splitbox__column:nth-child(1) .elementor-column-wrap {
      padding: 2rem;
    }
  }
}

// LIMIT CLASSES ============================================

.limit {
  margin: 0 auto;
  width: 83.33333%;

  &--10 {
    @extend .limit;
    width: 83.33333%;
  }

  &--8 {
    @extend .limit;
    width: 66.66666%;
  }

  &--6 {
    @extend .limit;
    width: 50%;
  }

  &--4 {
    @extend .limit;
    width: 33.33333%;
  }
}

// GRID ============================================

.flowgrid {
  .elementor-widget-wrap {
    display: flex;
    flex-flow: row wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    width: auto;

    .elementor-element {
      width: 100%;
      margin-right: 1rem;
      margin-left: 1rem;

      @include breakpoint(medium) {
        width: calc(50% - 2rem);
      }

      @include breakpoint(large) {
        width: calc(33.33333% - 2rem);
      }
    }
  }
}

.numbered {
  .elementor-element {
    position: relative;

    &:after {
      content: "";
    }
  }
}

.card-big {
  height: 100%;
  margin-bottom: 0;
  background: white;

  .elementor-image-box-content {
    padding: 1rem 1.5rem;
  }

  .elementor-image-box-description {
    text-align: left;
  }

  .elementor-image-box-title {
    text-align: left;
    font-size: 16px;
    color: $brandcolor1;
  }

  .elementor-image-box-img {
    margin-bottom: 0.5rem;
  }

  .elementor-image-box-img {
    overflow: hidden;
  }
}

.card-small {
  background: white;
  height: 100%;

  .elementor-image-box-title {
    font-size: 16px;
    line-height: 24px;
    color: $brandcolor2;
    text-align: left;
    margin: 0;
  }

  .elementor-image-box-content {
    padding: 0.5rem 1rem;
  }

  .elementor-image-box-img {
    overflow: hidden;
  }
}

.imageparagraph {
  .elementor-widget-wrap {
    display: flex;

    .elementor-widget {
      display: flex;

      .elementor-image {
        height: 100%;

        img {
          object-fit: cover;
          height: 100%;
          min-height: 260px;
        }
      }
    }

    .elementor-widget-text-editor {
      text-align: left;

      h3 {
        font-size: 20px;
      }
    }
  }
}

// TEASERSLIDER

.teaserslider {
  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    padding: 1rem;
    width: 680px;
    transition: 1s;

    &.slick-current {
      transform: scale(1.1);
      background: $brandcolor1;
      color: white;
      //margin: -1rem -1rem -1rem -1rem
    }

    .slide {
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
      padding: 1rem;
    }
  }
}

// WEBCHIMP CREDITS ====================================

.webchimp-credit {
  position: absolute;
  z-index: 10;
  right: 1rem;
  bottom: 0.6rem;
  display: block;
  background: url(../images/webchimp-heart.png) no-repeat center top;
  font-size: 0;
  background-size: 30px auto;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  transition: 0.3s;
  opacity: 0.4;

  &::after {
    content: "";
    background: url(../images/webchimp-icon-transparent.svg) no-repeat center
      center;
    background-size: cover;
    height: 0px;
    width: 0px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
    transition: 0.4s;
    display: block;
    transform-origin: center bottom;
  }

  &::before {
    content: attr(data-title);
    background: #88c426;
    color: white;
    border-radius: 4px;
    position: absolute;
    right: 40px;
    top: 2px;
    max-width: 0px;
    overflow: hidden;
    display: block;
    transition: 0.4s;
    transition-delay: 0.4s;
    z-index: -2;
    font-size: 12px;
    white-space: nowrap;
    padding: 4px 0;
  }

  &:hover {
    background-position: center bottom;
    opacity: 1;

    &::before {
      max-width: 1000px;
      padding: 4px 16px;
    }

    &::after {
      top: -25px;
      animation: wave 2s linear infinite alternate;
    }
  }
}

// IMAGEBOX

// CARD (elementor Afbeeldingen box)

.homepage-icons {
  .elementor-image-box-wrapper {
    display: flex;
    align-items: center;

    .figure {
      flex: 1 0 auto;
    }

    .elementor-image-box-title {
      text-align: left;
      color: white;
      font-family: $header-font-family;
      text-transform: uppercase;
      @include fluid-type(12px, 16px);
    }
  }
}

.homepage-iconss {
  > div {
    margin-bottom: 0;
    padding: 1rem;

    @include breakpoint(large) {
      padding: 2rem;
    }
  }

  // header
  .elementor-image-box-title {
    //padding: 1rem 1rem 0 1rem;

    a {
    }
  }

  // body
  .elementor-image-box-description {
    //padding: 0 1rem 1rem 1rem;

    a {
    }
  }

  // image box
  .elementor-image-box-img {
  }

  &--white {
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);

    .elementor-image-box-description,
    .elementor-image-box-title {
      color: white;
      color: $brandcolor1;
    }
  }

  &--purple {
    background: $brandcolor1;
    color: white;

    .elementor-image-box-description,
    .elementor-image-box-title {
      color: white;
    }
  }
}

// .cell {
//   outline: 1px solid grey;
// }

// FORCE COLUMNS WIDTH AUTO: TODO N.M.

.flex-columns {
  .elementor-column {
    width: auto !important;
    flex: auto;
  }
}

.profile-photo {
  .elementor-image figure figcaption {
    background: $brandcolor2;
  }
}

.action {
  //box-shadow: 0 0 24px 0 rgba(0,0,0,.2);
  padding: 0rem;
  text-align: center;
  position: relative;
  transition: 0.3s;
  border: 0;

  &:after {
    content: "";
    position: absolute;
    pointer-events: all;
    background: $brandcolor3;
    opacity: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.08;
  }

  &:hover {
    box-shadow: 0 0 24px 0 rgba(234, 238, 240, 1);
    transform: translateY(-2px);
  }

  &__quote {
    background: $brandcolor6;
    color: white;
    font-weight: bold;
    display: block;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    font-family: $header-font-family;
    z-index: 2;
    position: relative;
  }

  &__image {
  }

  &__title {
    @include fluid-type(16px, 18px);
    color: $brandcolor3;
  }

  &__price {
    color: $brandcolor3;
    @include fluid-type(16px, 18px);

    .regular {
      font-weight: 300;
      text-decoration: line-through;
    }
    .discount {
      font-weight: 600;
    }
  }
}

.eq-columns {
  .cell {
    display: flex;
    a {
      display: flex;
    }
  }
}

//TRAINERS

.trainer {
  background: white;
  //padding: 0 1rem 0 0;
  margin: 2rem 0;
  width: 100%;

  @include breakpoint(medium) {
    padding: 0 1rem 0 0;
  }

  p {
    margin: 0;
  }

  .media-object-section:last-child {
    text-align: center;
    padding: 1rem;
    width: 100%;
    word-wrap: break-word;
  }

  &__thumbnail img {
    max-width: none !important;
  }

  &__title {
    @include fluid-type(20px, 24px);
    color: $brandcolor3;
    text-align: center;
  }

  &__function {
    text-transform: uppercase;
    color: $brandcolor6;
    font-size: 13px;
  }

  &__bio {
    color: $brandcolor3;
    font-size: 16px;
  }
}

.site-header {
  .menu {
    font-family: $header-font-family;
  }
}

.content-area {
  padding: 2rem;

  .site-main {
    @extend .grid-container;
    margin: 0 auto;
  }
}

// woocommerce breadcrumb
.woocommerce-breadcrumb {
}

.before-shop-loop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body .woocommerce .woocommerce-ordering {
  margin: 0;

  select {
    margin: 0;
  }
}

.yith-wcwl-add-to-wishlist {
  //display: none;
  //position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 100;

  .product:hover & {
    display: block;
  }

  .add_to_wishlist {
  }

  &:hover {
    .add_to_wishlist {
      display: block;
      position: absolute;
    }
  }
}

// HOVERS

.mark-ltr {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    background: $brandcolor2;
    height: 4px;
    transition: 0.5s;
    //transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .active & {
    color: red;
    &:after {
      right: 0;
    }
  }

  &:hover {
    &:after {
      right: 0;
    }
  }
}



.gutter-right {
  padding-right: 1rem;
  position: relative;
}

.gutter-left {
  padding-left: 1rem;
}

.action-show {
  background: white url(../images/icons/icon-show.svg) no-repeat left 8px center;
  display: block;
  margin: 0 0.5rem;
  padding: 0.5rem 1rem 0.5rem 2rem;
  font-size: 14px;
  color: $brandcolor4;
}

.action-cart {
  background: white url(../images/icons/icon-shoppingbag.svg) no-repeat left 8px
    center;
  display: block;
  margin: 0 0.5rem;
  padding: 0.5rem 1rem 0.5rem 2rem;
  font-size: 14px;
  color: $brandcolor4;
  position: relative;
  transition: 0.5s;

  &:hover {
    color: $brandcolor2;
  }

  a {
    text-indent: -999px;
    font-size: 0;
    background: none !important;
    overflow: hidden !important;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
  }
}

.action-wishlist {
  margin: 0 0.5rem;
  display: block;
  overflow: hidden;
  width: 40px;
  //icons-wishlist-small.svg

  .yith-wcwl-add-to-wishlist {
    width: 42px;
  }

  .add_to_wishlist {
    .add_to_wishlist {
      background: url(../images/icons-wishlist-small.svg) no-repeat center
        center;
    }

    .yith-wcwl-wishlistaddedbrowse.show a,
    .yith-wcwl-wishlistexistsbrowse.show a {
      background: url(../images/icons-wishlist-small.svg) no-repeat center
        bottom;
    }
  }
}

// RATINGBOX

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0.5rem auto;

  &__stars {
    background: url(../images/start-rating.svg) no-repeat left top;
    height: 18px;
    width: 120px;
    background-size: cover;

    &.stars--2 {
      background-position: left top -29px;
    }

    &.stars--3 {
      background-position: left top -58px;
    }

    &.stars--4 {
      background-position: left top -88px;
    }

    &.stars--5 {
      background-position: left top -118px;
    }
  }

  &__count {
    padding: 0 0.5rem;
  }
}

.widget_price_filter {
  .price_slider {
    margin-bottom: 1em;
  }

  .price_slider_amount {
    text-align: right;
    line-height: 2.4;
    font-size: 0.8751em;

    .button {
      float: left;
      padding: 0.4em 1em;
    }
  }

  .ui-slider {
    position: relative;
    text-align: left;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1em;
    height: 1em;
    background-color: #000;
    border-radius: 1em;
    cursor: ew-resize;
    outline: none;
    top: -0.3em;
    margin-left: -0.5em;
  }

  .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: 0.7em;
    display: block;
    border: 0;
    border-radius: 1em;
    background-color: #000;
  }

  .price_slider_wrapper .ui-widget-content {
    border-radius: 1em;
    background-color: #666;
    border: 0;
  }

  .ui-slider-horizontal {
    height: 0.5em;
  }

  .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
  }

  .ui-slider-horizontal .ui-slider-range-min {
    left: -1px;
  }

  .ui-slider-horizontal .ui-slider-range-max {
    right: -1px;
  }
}

.widget_rating_filter {
  li {
    text-align: right;

    .star-rating {
      float: left;
      margin-top: 0.3em;
    }
  }
}

.widget_product_search {
  form {
    position: relative;
  }

  .search-field {
    padding-right: 100px;
  }

  input[type="submit"] {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
  }
}



// WOOCOMMERCE TYPOGRAPHY

.woocommerce {
  .product-title {
    @include fluid-type(28px, 32px);
    color: $brandcolor2;
    margin-bottom: 1rem;
  }

  .woocommerce-product-rating {
    margin-bottom: 1rem;

    .star-rating {
      color: $brandcolor4;
    }
  }

  .price {
    @include fluid-type(18px, 22px);
    color: $brandcolor6;

    del {
      font-weight: 300;
    }

    ins {
      font-weight: 600;
    }

    &--small {
      @include fluid-type(14px, 16px);
    }
  }

  .cart {
    .variations {
      border-collapse: collapse;
      width: auto;

      .reset_variations {
        display: none !important;
      }

      select {
        border: 2px solid #e5e8e9;
        transition: 0.5s;
        background: transparent url(../images/select-arrow.png) no-repeat right
          1rem top 1.1rem;
        background-size: 12px 9px;
        padding: 0.7rem 2.5rem 0.8rem 0.5rem;
        line-height: 1.2;
        height: auto;

        &:hover {
          border: 2px solid $brandcolor2;
        }
      }

      tbody {
        border: 0;
      }

      .label {
        background: none;
        color: $brandcolor2;
        padding: 1rem 0;
      }

      .reset_variations {
        display: none;
      }
    }
  }
}

html body.woocommerce {
  // VARIATIONS

  .woocommerce-variation {
    &-description {
    }

    &-price {
    }

    &-availability {
      font-weight: bold;
      color: $brandcolor4;
      margin-bottom: 1rem;

      .out-of-stock {
        color: #ff0000;
      }
    }
  }

  // MESSAGE / ERRORMESSAGE

  .woocommerce-message {
    max-width: $global-width;
    background: $brandcolor6;
    margin: 2rem auto 0 auto;
    color: white;
    padding: 1rem 2rem;
    min-height: 5rem;

    a {
      background: white;
      border-radius: 2px;
      color: $brandcolor6;
      font-weight: 600;
      float: right;
      margin-bottom: 0;
    }
  }

  .woocommerce-error {
    max-width: $global-width;
    background: red;
    margin: 2rem auto 0 auto;
    color: white;
    padding: 1rem 2rem;
    min-height: 5rem;
    list-style: none;

    a {
      background: white;
      border-radius: 2px;
      color: red;
      font-weight: 600;
      float: right;
      margin-bottom: 0;
    }
  }





  // enable wrapping
  .woocommerce-variation-add-to-cart,
  .add-to-cart {
    flex-wrap: wrap;
    display: flex;
    align-items: stretch;

    form.cart {
      display: flex;
      flex-wrap: wrap;
    }

    // hier styles voor cpo options
    #uni_cpo_options {
      flex: 0 0 auto;
      //border: 1px solid red;
    }

    .quantity {
      border: 2px solid $brandcolor2;
      border-right: 0;
      width: 60px;

      input {
        margin: 0;
        border: 0;
        font-family: $header-font-family;
        text-align: center;
        height: 100%;
      }
    }

    .button {
      border: 2px solid $brandcolor2;
      background-color: transparent !important;
      border-radius: 0;
      color: $brandcolor2 !important;
      text-transform: uppercase;
      font-family: $header-font-family;
      padding: 1rem;
      opacity: 1;
      margin: 0;
    }
  }
}



.stock.in-stock {
  flex: 0 0 auto;
  width: 100%;
  color: $brandcolor6;
  font-weight: 600;
}

.custom-label {
  color: white;
  background: $brandcolor2;
  padding: 0.5rem;
  border-radius: 4px;
}

// wishlist
.yith-wcwl-add-to-wishlist {
  width: 40px;
  height: 40px;
  margin-top: 0 !important;
  position: absolute;
  top: 1rem;
  right: 3rem;

  img {
    position: absolute;
  }

  .feedback {
    display: block;
    opacity: 0.6;
    // @extend .label;
    // position: absolute;
    // left: 42px;
    // top: 0;
    // display: none;
  }

  &:hover {
    .yith-label {
      display: block;
    }
  }
}

.yith-label {
  @extend .custom-label;
  position: absolute;
  top: 5px;
  right: 3rem;
  display: none;
  margin-left: 50px;
  width: 200px;
}

.add_to_wishlist {
  display: block;
  width: 42px;
  height: 42px;
  background: url(../images/icons-wishlist.svg) no-repeat center top;

  &:hover {
    background-position: center center;
  }
}

.yith-wcwl-wishlistaddedbrowse.show a,
.yith-wcwl-wishlistexistsbrowse.show a {
  display: block;
  width: 42px;
  height: 42px;
  background: url(../images/icons-wishlist.svg) no-repeat center bottom;
}

.wishlistaddedbrowse .yith-label {
  display: block;
}

// RELATED PRODUCTS

.related.products {
  text-align: center;

  .products {
    list-style: none;
    margin: 0;
  }
}

.related.products {
  h2 {
    @extend .section-title;
  }
}


// WOOCOMMERCE STYLE OVERRIDES
.woocommerce,
.woocommerce-page {
  .idTag {
    height: 48px;

    @include breakpoint (medium) {
      height: 70px;
    }

    @include breakpoint (large) {
      height: 100px;
    }
  }
}



.productbrand {
  &__logo {
    width: 60px;
    height: auto;
  }
}

// <div class="productbrand">
//   <img src="{{ TimberImage(merk.get_field('logo'))|resize(100,auto) }}" alt="{{merk.name}}" class="productbrand__logo" />
// </div>  


.page404 {
  text-align: center;
  padding-top: 8rem;

  &__bottom {
    background: white;
    color: $brandcolor3;
    padding: 0 2rem 2rem 2rem;

    @include breakpoint(large) {
      padding: 4rem;
    }
  }
}